import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bookFlight: null,
};

const flightBookingSlice = createSlice({
  name: "flightBook",
  initialState,
  reducers: {
    setBookFlight: (state, action) => {
      state.bookFlight = action.payload;
    },
    reset: (state) => {
      state.bookFlight = initialState.flightBook;
    },
  },
});

export const { setBookFlight, reset } = flightBookingSlice.actions;

export default flightBookingSlice.reducer;
